// pages/index.jsx
"use client";
import React, { memo } from "react";
import dynamic from "next/dynamic";
import Link from "next/link";
import OverlayImage from "@/components/OverlayImage";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Slider = dynamic(() => import("react-slick"), { ssr: false });

const Portfolio = ({
  // title = "We Design Websites and Apps for small and medium size businesses",
  title = "Portfolio",
}) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="bg-white w-full py-10 md:py-20 lg:py-18" id="portfolio">
      <div className="w-full mx-auto">
        <div className="text-center mb-10 md:mb-8">
          <h2 className="font-bold text-color-brown-600">{title}</h2>
        </div>

        <Slider
          {...settings}
          className="portfolio-slider w-full overflow-hidden"
        >
          <OverlayImage
            imageSrc="/images/portfolio/thumbnail/angelstar.jpg"
            altText="Angel Star Ventures"
            text="Angel Star Ventures"
            linkUrl="/portfolio/angelstarventures"
          />

          <OverlayImage
            imageSrc="/images/portfolio/thumbnail/business.jpg"
            altText="Wise Business Plans"
            text="Wise Business Plans"
            linkUrl="/portfolio/wisebusinessplans"
          />
          <OverlayImage
            imageSrc="/images/portfolio/thumbnail/fliving.jpg"
            altText="Future Net Wings"
            text="Future Net Wings"
            linkUrl="/portfolio/futurenetwings"
          />
          <OverlayImage
            imageSrc="/images/portfolio/thumbnail/aeroboom-new.jpg"
            altText="Aeroboom"
            text="Aeroboom"
            linkUrl="/portfolio/aeroboom"
          />
        </Slider>

        <div className="text-center mt-10 md:mt-8">
          <Link
            href="/portfolio"
            className="px-6 py-3 bg-color-orange-500 text-black font-bold rounded-md shadow-md hover:bg-color-orange-600"
          >
            See Our Portfolio
          </Link>
        </div>
      </div>
    </section>
  );
};

export default memo(Portfolio);
