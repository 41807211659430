"use client";
import React from "react";
import {
  Accordion,
  AccordionContent,
  AccordionPanel,
  AccordionTitle,
} from "flowbite-react";
import {
  itemVariants,
  childVariants,
  parentVariants,
} from "../constants/motion";
import { motion } from "framer-motion";
import Image from "next/image";

// Component accepts title and qaPairs (Question and Answer pairs) as props
const Leveraging = ({ title, qaPairs, bg_image = "" }) => {
  return (
    <>
      <section className="w-full">
        <div className="max-w-screen-xl mx-5 lg:mx-auto flex flex-col py-10">
          <div className="flex flex-col md:flex-row rounded-lg">
            <div className="flex-1 basis-3/5 order-2 md:order-1">
              <div className="flex flex-col justify-center h-full px-5">
                <h2 className="font-semibold text-left pb-4 leading-tight text-color-blue-800">
                  {title}
                </h2>
                <Accordion className="accordion-features" collapseAll>
                  {qaPairs.map(({ question, answer }, index) => (
                    <AccordionPanel key={index}>
                      <AccordionTitle>{question}</AccordionTitle>
                      <AccordionContent>
                        <p className="mb-2 text-gray-800 dark:text-gray-400">
                          {answer}
                        </p>
                      </AccordionContent>
                    </AccordionPanel>
                  ))}
                </Accordion>
              </div>
            </div>
            <motion.div
              variants={childVariants}
              className="bg-blue-100 flex-1 basis-3/5 rounded-lg order-1 md:order-2 relative"
            >
              <Image
                src={bg_image}
                alt={title}
                fill
                className="z-50 absolute w-full h-full object-cover"
              />
            </motion.div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Leveraging;
