import(/* webpackMode: "eager" */ "/home/shankesh/webdesign/prateekshawebdesign.com/next.js/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/home/shankesh/webdesign/prateekshawebdesign.com/next.js/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/shankesh/webdesign/prateekshawebdesign.com/next.js/src/components/ArticleBox.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/shankesh/webdesign/prateekshawebdesign.com/next.js/src/components/Benefits.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/shankesh/webdesign/prateekshawebdesign.com/next.js/src/components/Faq.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/shankesh/webdesign/prateekshawebdesign.com/next.js/src/components/IndustrySection.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/shankesh/webdesign/prateekshawebdesign.com/next.js/src/components/Intro.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/shankesh/webdesign/prateekshawebdesign.com/next.js/src/components/LatestBlog.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/shankesh/webdesign/prateekshawebdesign.com/next.js/src/components/LaunchYourProject.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/shankesh/webdesign/prateekshawebdesign.com/next.js/src/components/Leveraging.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/shankesh/webdesign/prateekshawebdesign.com/next.js/src/components/Portfolio.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/shankesh/webdesign/prateekshawebdesign.com/next.js/src/components/ServicesCarousel.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/shankesh/webdesign/prateekshawebdesign.com/next.js/src/components/Technologies.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/shankesh/webdesign/prateekshawebdesign.com/next.js/src/components/USP.js");
