"use client";
import React, { useMemo } from "react";
import Image from "next/image";
import Link from "next/link";
import { motion } from "framer-motion";
import {
  itemVariants,
  childVariants,
  parentVariants,
} from "../constants/motion";

const IndustrySection = ({ title, description, icons }) => {
  const memoizedIcons = useMemo(() => icons, [icons]);

  return (
    <motion.section
      variants={parentVariants}
      initial="initial"
      whileInView="animate"
      className="w-full flex flex-col justify-center items-center bg-color-brown-100 py-20"
    >
      <div className="max-w-screen-xl flex flex-col mx-5 lg:mx-auto text-center">
        <motion.h2
          variants={childVariants}
          className="font-bold mb-6 text-color-blue-800"
        >
          {title}
        </motion.h2>
        <motion.p variants={childVariants} className="mx-auto text-gray-600">
          {description}
        </motion.p>
      </div>

      <div className="max-w-screen-xl mx-5 lg:mx-auto grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 items-center justify-center gap-6 px-4 pt-9 font-bold text-lg">
        {memoizedIcons.map((icon, index) => (
          <motion.div
            key={index}
            variants={itemVariants.left}
            className="text-center"
          >
            <Link href={icon.link} className="hover:text-color-orange-500">
              <Image
                src={icon.image}
                alt={icon.title}
                width={80}
                height={80}
                className="mx-auto text-color-orange-500 hover:text-color-orange-500"
              />
              <div className="mt-2">{icon.title}</div>
            </Link>
          </motion.div>
        ))}
      </div>
    </motion.section>
  );
};

export default React.memo(IndustrySection);
