"use client";
import React from "react";
import Image from "next/image";
import Link from "next/link";

const OverlayImage = ({
  imageSrc,
  altText,
  text,
  linkUrl,
  width = 580,
  height = 524,
}) => {
  return (
    <div className="relative w-full h-full ">
      {linkUrl ? (
        <Link href={linkUrl} className="block mx-2">
          <Image
            className="h-auto w-full rounded-lg "
            src={imageSrc}
            alt={altText}
            width={width}
            height={height}
          />
          {/* <div className="absolute bottom-0 left-0 w-full bg-black bg-opacity-50 text-white text-center p-4">
            {text}
          </div> */}
        </Link>
      ) : (
        <>
          <Image
            className="h-auto w-full rounded-lg"
            src={imageSrc}
            alt={altText}
            layout="responsive"
            width={580}
            height={524}
          />
          <div className="absolute bottom-0 left-0 w-full bg-black bg-opacity-50 text-white text-center p-4">
            {text}
          </div>
        </>
      )}
    </div>
  );
};

export default OverlayImage;
